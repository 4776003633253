import styled from "styled-components"

export const WizytaSectionWrapper = styled.section`
  h4 {
    margin-top: var(--spacing-8);
    margin-bottom: var(--spacing-3);
    line-height: var(--lineHeight-tight);
    border-bottom: 1px solid var(--color-accent);
    border-color: rgb(161, 161, 161);
    padding-bottom: var(--spacing-1);
  }

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    all: unset;
  }
  table {
    thead tr th {
      display: none;
    }

    tbody {
      tr {
        td:first-of-type {
          font-weight: var(--fontWeight-medium);
        }
        td:first-of-type:after {
          content: " - ";
        }
        td:nth-of-type(2),
        td:nth-of-type(3) {
          font-style: italic;
        }
        td:last-of-type {
          display: block;
          margin-bottom: var(--spacing-3);
        }
      }
    }
  }
`
